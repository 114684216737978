import { styled } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { OptionType } from 'dayjs'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column'
})

const StyledLabel = styled('label')({
  fontSize: '0.9rem',
  marginBottom: '0.5rem',
  '&.--disabled': {
    color: 'gray',
    opacity: 0.5
  }
})

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  borderRadius: '2rem',
  color: theme.palette.text.primary,
  display: 'flex',

  '& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input.MuiInputBase-inputSizeSmall':
    {
      fontSize: '0.8rem'
    },

  '& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input': {
    padding: '0 0.8rem 0 0.6rem',
    minWidth: ' 90px',
    fontSize: '0.9rem'
  },

  '& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input:-webkit-autofill':
    {
      boxShadow: '0 0 0 100px #f9f9f9e5 inset',
      color: theme.palette.text.primary,
      '-webkit-text-fill-color': theme.palette.text.primary
    },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: ' #d9d9d9',
    borderRadius: ' 2rem'
  },

  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main
  }
}))

export const SelectAutoComplete = <T extends { label: string; value?: T['value'] | null }>({
  options,
  onChange,
  placeholder = 'Selecione',
  label,
  disabled,
  isLoading,
  value: valueProp,
  defaultValue,
  hideLabel,
  size = 'medium',
  currentSelected
}: {
  options: T[]
  onChange: (option?: T | null) => void
  placeholder?: string
  value?: T['value'] | null
  label?: string
  disabled?: boolean
  isLoading?: boolean
  defaultValue?: T['value'] | null
  hideLabel?: boolean
  size?: 'small' | 'medium'
  currentSelected?: T['value'] | null
}) => {
  const [value, setValue] = useState<T['value']>(valueProp || null)

  const handleChange = (_event: React.SyntheticEvent, newValue: (OptionType & T) | null) => {
    setValue(newValue)
    onChange(newValue as T)
  }

  const handleCurrentSelected = useCallback(() => {
    if (currentSelected) {
      const option = options?.find((option) => option.value === currentSelected)
      console.log('[handleCurrentSelected, 93] option', option)
      if (option) {
        setValue(option)
      }
    }
  }, [currentSelected, options])

  useEffect(() => {
    handleCurrentSelected()
  }, [handleCurrentSelected])

  return (
    <StyledContainer>
      {label && !hideLabel && <StyledLabel className={disabled ? '--disabled' : ''}>{label}</StyledLabel>}
      <StyledAutocomplete
        options={options}
        autoHighlight
        size={size}
        value={value}
        getOptionLabel={(option) => (option as T).label}
        // @ts-ignore
        onChange={handleChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault()
            event.stopPropagation()
          }
        }}
        disabled={disabled}
        loading={isLoading}
        defaultValue={defaultValue}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props
          return (
            <Box key={key} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...optionProps}>
              {(option as T).label}
            </Box>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            slotProps={{
              htmlInput: {
                ...params.inputProps,
                placeholder,
                autoComplete: 'new-password' // disable autocomplete and autofill
              }
            }}
          />
        )}
      />
    </StyledContainer>
  )
}
