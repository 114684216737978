import FilterListIcon from '@mui/icons-material/FilterList'
import { Box, Divider, FormControl, FormLabel, IconButton, Menu, Tooltip, useTheme } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'

import { FilterByBudget } from './FilterByBudget'
import { FilterByCategory } from './FilterByCategory'
import { FilterByPaymentMethod } from './FilterByPaymentMethod'
import { SelectBudgetType } from './SelectBudgetType'

const checkIfHasAnyValue = (filter: object) => {
  return Object.values(filter).some((value) => !!value)
}

export interface IFilter {
  type: string
  categoryId: number
  paymentMethodId: number
  budgetId: number
}

export const DEFAULT_FILTER: IFilter = {
  type: '',
  categoryId: 0,
  paymentMethodId: 0,
  budgetId: 0
}

export const FilterSelectsByCategory = ({
  onFilterChange,
  activeFilters
}: {
  onFilterChange?: (filter: IFilter) => void
  activeFilters?: IFilter
}) => {
  const { palette } = useTheme()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [hasAnyValue, setHasAnyValue] = useState(false)
  const [filter, setFilter] = useState<IFilter>(activeFilters ?? DEFAULT_FILTER)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const onChangeValue = ({
    name,
    option
  }: {
    name: 'type' | 'categoryId' | 'paymentMethodId' | 'budgetId'
    option: { value: string | number; label: string } | null | undefined
  }) => {
    const value = option?.value
    const newFilter = { ...filter, [name]: value }
    setFilter(newFilter)
    onFilterChange?.(newFilter)
  }

  const handleCheckIfHasAnyValue = useCallback(() => {
    const hasAnyValue = checkIfHasAnyValue(filter)
    setHasAnyValue(hasAnyValue)
  }, [filter])

  useEffect(() => {
    handleCheckIfHasAnyValue()
  }, [handleCheckIfHasAnyValue])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        position: 'relative'
      }}
    >
      <Tooltip title="Filtrar por categoria">
        <IconButton
          onClick={handleClick}
          sx={{
            color: hasAnyValue ? 'primary.main' : 'inherit',
            backgroundColor: hasAnyValue ? palette.primary.light : 'inherit'
          }}
        >
          <FilterListIcon
            sx={{ color: hasAnyValue ? palette.getContrastText(palette.primary.light) : 'inherit' }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setOpen(false)}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        sx={{
          '& .MuiPaper-root': {
            padding: '0.6rem 0.7rem'
          }
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <FormControl size="small">
            <FormLabel>Categoria</FormLabel>
            <FilterByCategory
              onChange={(option) => onChangeValue({ name: 'categoryId', option })}
              size="small"
              currentSelected={filter?.categoryId}
            />
          </FormControl>
          <Divider />

          <FormControl size="small">
            <FormLabel>Meio de pagamento</FormLabel>
            <FilterByPaymentMethod
              onChange={(option) => onChangeValue({ name: 'paymentMethodId', option })}
              size="small"
            />
          </FormControl>
          <Divider />

          <FormControl size="small">
            <FormLabel>Tipo de orçamento</FormLabel>
            <SelectBudgetType
              currentSelected={''}
              size="small"
              onChange={(option) => onChangeValue({ name: 'type', option })}
            />
          </FormControl>
          <Divider />

          <FormControl size="small">
            <FormLabel>Orçamento</FormLabel>
            <FilterByBudget onChange={(option) => onChangeValue({ name: 'budgetId', option })} size="small" />
          </FormControl>
        </Box>
      </Menu>
    </Box>
  )
}
