import { useCoreContext } from '../../../../hooks/contexts/coreContext/coreProvider'
import { useFamilyMeiosPagamentos } from '../../../../queries/meioPagamento/queries'
import { SelectAutoComplete } from '../../../Mui/SelectAutoComplete'

interface Option {
  label: string
  value: number
}

interface FilterByPaymentMethodProps {
  onChange?: (option?: Option | null) => void
  size?: 'small' | 'medium'
}

export const FilterByPaymentMethod = ({ onChange, size = 'medium' }: FilterByPaymentMethodProps) => {
  const { familiaId: familyId } = useCoreContext()
  const { meiosPagamentos, isLoadingMeios } = useFamilyMeiosPagamentos(familyId ?? undefined)

  const options =
    meiosPagamentos?.map(({ id, nome }) => ({
      value: id,
      label: nome
    })) ?? []

  return (
    <SelectAutoComplete<Option>
      options={options}
      size={size}
      isLoading={isLoadingMeios}
      onChange={(option) => {
        onChange?.(option)
      }}
    />
  )
}
